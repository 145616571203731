<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.CreateEvent.Main_Title')"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="createEvent"
    :isSavingSuccess="isSavingSuccess"
    :savingSuccessMessage="savingSuccessMessage"
    :savingErrorMessage="savingErrorMessage"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div>
        <label>{{ $t('Components.Events.CreateEvent.Label_EventName') }}</label>
        <ui-base-input-field
          v-model="eventName"
          :ref="eventName"
          :error="nameErrors"
          :required="true"
          :showLabel="false"
          fieldType="text"
          @keyup.enter="createEvent"
          @input="$v.eventName.$touch()"
          @blur="$v.eventName.$touch()"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations } from 'vuex'
import eventProvider from '@/providers/event'

import { required, minLength } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locationId: Number(this.$route.params.locationId),
      location: null,
      eventName: '',
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      savingSuccessMessage: 'Event created',
      savingErrorMessage: 'Oops something went wrong',
    }
  },

  /**
   * Form validation rules
   */
  validations: {
    eventName: {
      required,
      minLength: minLength(2),
    },
  },

  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.eventName.$dirty) return errors
      !this.$v.eventName.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.eventName.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
      return errors
    },
  },

  created() {},

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    createEvent() {
      let self = this
      if (!self.isSaving) {
        self.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          self.isSaving = false
        } else {
          eventProvider.methods
            .createEvent(
              this.locationId,
              this.eventName,
              this.reservation.StartDate, // startDate,
              this.reservation.EndDate, // endDate,
              this.reservation.StartMinutes, // startMinutes,
              this.reservation.StartMinutes, // endMinutes,
              this.reservation.Tags, // tags
              this.reservation.Id //reservationId
            )
            .then((response) => {
              if (response.status === 201) {
                self.isSavingSuccess = true
                let t = setTimeout(() => {
                  self.onClickCancel()

                  //console.log("response data?", response.data)
                  self.setEventData(response.data)
                  self.$emit('getReservationEvent')

                  // self.$router.push({ name: 'events-list-detail', params: { eventId: response.data.Id } })

                  clearTimeout(t)
                }, 1500)
              }
            })
            .catch((e) => {
              self.isSavingError = true
              let t = setTimeout(() => {
                self.isSavingError = false
                clearTimeout(t)
              }, 1000)
            })
            .finally(() => {
              self.isSaving = false
            })
        }
      }
    },
  },
}
</script>
